import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyleV1 } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  width: 100%;
`;

const img = styled.div`
  text-align: center;
  padding-top: 100px;
  padding-bottom: 80px;

  ${mobile(css`
    padding-top: 60px;
    padding-bottom: 24px;
  `)}

  > img {
    width: 200px;
    height: 200px;

    ${mobile(css`
      width: 96px;
      height: 96px;
    `)}
  }
`;

const textGroup = styled.div`
  text-align: center;
  color: ${COLOR.grayScale_500};
  padding-bottom: 48px;

  ${mobile(css`
    padding-bottom: 40px;
  `)}

  > .text-bold {
    ${setFontStyleV1("Head3", "Bold")};

    ${mobile(css`
      ${setFontStyleV1("Head5", "Bold")};
    `)}
  }

  > .text-normal {
    ${setFontStyleV1("Body3")};

    ${mobile(css`
      ${setFontStyleV1("Body6")};
    `)}
  }
`;

const backAction = styled.div`
  padding-bottom: 120px;
  text-align: center;

  ${mobile(css`
    padding-bottom: 0px;
  `)}
`;

export default {
  container,
  img,
  textGroup,
  backAction,
};
